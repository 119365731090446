<template lang="html">
  <transition name="dialog-t" >
    <div v-if="isPrompt ? active : fActive" ref="con" :class="[`vs-dialog-${color}`]" class="shipblu-prompt vs-component con-vs-dialog">
      <div class="vs-dialog-dark" @click="handleClose($event,true)"/>
      <div ref="dialogx" class="vs-dialog">
        <!-- //header -->
        <div class="p-4">
          <div class="flex justify-end">
            <feather-icon class="text-icon cursor-pointer" v-if="type=='alert'" :icon="closeIcon" svgClasses="h-5 w-5" :icon-pack="iconPack" @click="handleClose($event)"/>
          </div>
          <div class="text-center">
            <h3 class="mt-2 font-medium text-primary text-xl">{{ title }} </h3>
          </div>
        </div>
        <div class="mt-5 text-center">
          <img :src="img"/>
        </div>
        <!-- // slots  -->
        <div class="prompt-font mt-3 pb-6 text-lg text-center vs-dialog-text">
          <slot/>
          <p>{{ text }}</p>
          <p>{{ optionalText }}</p>
        </div>
      </div>
     </div>
  </transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

/* eslint-disable */
export default {
  name:'VsPrompt',
  props:{
    color:{
      default:'primary',
      type:String
    },
    active:{
      default:false,
      type: Boolean
    },
    buttonAccept:{
      default:'filled',
      type:String,
    },
    buttonCancel:{
      default:'flat',
      type:String,
    },
    isValid:{
      default:'none',
      type:[Boolean,String]
    },
    buttonsHidden:{
      default:false,
      type:Boolean
    },
    acceptText:{
      default:'Accept',
      type:String
    },
    cancelText:{
      default:'Cancel',
      type:String
    },
    iconPack:{
      default:'feather',
      type:String
    },
    closeIcon:{
      default:'XSquareIcon',
      type:String
    },
    text:{
      default: null,
      type: String
    },
    title:{
      default: 'Dialog',
      type: String
    },
    optionalText:{
      default: null,
      type: String
    },
    img: {
      default: null,
      type: String
    },
    type:{
      default: 'alert',
      type: String
    },
    parent:{
      default: null,
    }
  },
  data:()=>({
    settings: {
      maxScrollbarLength: 60,
      wheelSpeed: 0.30
    },
    isPrompt:true,
    fActive: false,
    parameters: null,
  }),
  watch:{
    active(val) {
      this.$nextTick(() => {
        if (this.active) {
          this.insertBody()
        }
      })
    },
    fActive() {
      this.$nextTick(() => {
        if (this.fActive) {
          this.insertBody()
        }
      })
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  mounted () {
    if (this.active && this.isPrompt) {
      this.insertBody()
    }
    this.fActive = this.active
  },
  beforeDestroy() {
    // close the left open prompt
    let elx = this.$refs.con
    let parentx = this.parent ? this.parent : document.body
    if (elx) {
      parentx.removeChild(elx)
    }
  },
  methods:{
    acceptDialog () {
      this.$emit('acceptFunction')
    },
    rebound(){
      this.$refs.dialogx.classList.add('locked')
      setTimeout( () => {
        this.$refs.dialogx.classList.remove('locked')
      }, 200);
    },
    handleClose(event,con){
      if(con){
        if(event.target.className.indexOf('vs-dialog-dark')!=-1 && this.type == 'alert'){
          this.fActive = false
          this.$emit('update:active',false)
        } else if (event.target.className.indexOf('vs-dialog-dark')!=-1) {
          this.rebound()
        }
      } else {
          this.$emit('update:active',false)
          this.fActive = false
      }
      this.$emit('close')
    },
    cancelClose(){
      this.fActive = false
      this.$emit('update:active',false)
      this.$emit('cancel')
      this.cancel?this.cancel(this.parameters):null

    },
    insertBody(){
      let elx = this.$refs.con
      let parentx = this.parent ? this.parent : document.body
      parentx.insertBefore(elx, parentx.firstChild)
    },
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

<style lang="scss" scoped>
.con-vs-dialog .vs-dialog {
  max-width: 650px;
}
.border {
  width: 100px;
  border: 1px solid #9DADC2;
}
.btn {
  color: #fff;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  background: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
  border-radius: 4px;
}
</style>