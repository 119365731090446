<template>
  <div class="my-2">
    <div class="cursor-pointer">
      <div @click="openUploadInvoicesModel" class="flex available-action px-4 py-2">
        <span class="material-icons text-lg self-center">upload</span>
        <span class="text-sm ml-2">{{ $t('Upload Accepted E-Invoices') }}</span>
      </div>
    </div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeUploadInvoiceModal"
      :active.sync="uploadInvoiceModal"
      :title="$t('Upload Accepted E-Invoices')"
      :buttons-hidden="true">
      <input type="file" ref="fileInput" class="hidden" accept=".json" @change="handleClick">
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="w-3/4 m-auto mt-5 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
        <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
        <span>{{$t('Drop Json File or')}} </span>
        <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
      </div>
      <div v-if="file" class="w-4/5 m-auto text-center mb-3">
        <vs-progress :percent="progress" color="primary"></vs-progress>
        <p>{{progress}}%</p>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import axios from '../../../axios.js'
import i18nData from '../../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  data () {
    return {
      common,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      uploadInvoiceModal: false,
      file: false,
      progress: 0,
      myProgress: 0,
      closeInterval: 0
    }
  },
  watch: {
    'progress' (val) {
      if (val === 95) {
        clearInterval(this.myProgress)
      }
    }
  },
  methods: {
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Warning',
          text: 'Only supports upload .json files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isJson(rawFile)) {
        this.$vs.notify({
          title: 'Warning',
          text: 'Only supports upload .json files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
      this.uploadFile(rawFile)
    },
    isJson (file) {
      return /\.(json)$/.test(file.name)
    },
    uploadFile (file) {
      if (!this.isJson(file)) {
        this.$vs.notify({
          color: 'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: 'Only supports upload .json files',
          position: 'top-center'
        })
        return false
      }
      this.$refs['fileInput'].value = null // fix can't select the same excel
      this.progress = 0
      this.file = true
      const formData = new FormData()
      formData.append('accepted_invoices', file)
      if (!this.isOnline) this.uploadInvoiceModal = false
      this.myProgress = setInterval(() => {
        this.progress += 1
      }, 500)
      axios.post('api/v1/accounting/e-invoices/accepted-invoices/', formData, {headers: this.headers})
        .then((response) => {
          this.progress = 100
          clearInterval(this.myProgress)
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.closeInterval = setInterval(() => {
            this.uploadInvoiceModal = false
            this.file = false
          }, 700)
        })
        .catch((error) => {
          this.progress = 100
          clearInterval(this.myProgress)
          common.notifyResponseError(this, error)
        })
    },
    closeUploadInvoiceModal () {
      this.uploadInvoiceModal = false
      this.progress = 0
      this.file = false
    },
    openUploadInvoicesModel () {
      clearInterval(this.closeInterval)
      this.uploadInvoiceModal = true
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>
